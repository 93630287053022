
.product-feature{
    text-align: left;
    padding: 5%;
    margin: 0px 0px;
}

.product-feature-expanded{
    box-shadow: 0 0.375rem 0.875rem 0 rgba(0,0,0,.13), 0 0.0625rem 0.25rem 0 rgba(0,0,0,.11);
    border-radius: 0.5rem;
    transition: all .6s cubic-bezier(.19,1,.22,1);
    background-color: white;
    /* height: 20%; */
}

.product-feature-title{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.product-feature-menu-arrow {
    margin-top: 0.6rem;
    margin-right: 0.3rem;
    vertical-align: middle;
    transition: all 2s ease-in-out;
}

.product-feature-title h2{
    /* font-size: 30px; */
    font-weight: 400;
    margin: 0px 0px;
}

.product-feature-description-container{
    display: none;
}

.product-feature-description-container-visible{
    display: flex;
}

.product-feature-description{
    padding: 2% 0;
    font-size: 18px;
    font-weight: 300;
    margin: 0px 0px;
}

.product-feature-menu-arrow-small {
    display: none;
}

@media (max-width: 768px) {
    .product-feature-menu-arrow-small {
        display: unset;
        margin-top: -0.1rem;
        margin-right: 0.2rem;
        vertical-align: middle;
        transition: all 2s ease-in-out;
    }
    .product-feature-menu-arrow {
        display: none;
    }

    .product-feature-title svg {
        width: 0.8rem;
        height: 0.8rem;
    }

    .product-feature-title h2{
        font-size: 1rem;
    }

    .product-feature-description{
        padding: 2% 0;
        font-size: 0.8rem;
        font-weight: 300;
        margin: 0px 0px;
    }
}

/* #on hover of .product-feature-title:hover show .product-feature-description*/
