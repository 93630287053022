.integrations-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.integrations-container .email-box-container {
    margin: 0%;
    margin-bottom: 3%;
}

.integrations-small-wave-container {
    background-color: #c4e1ed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.integrations-small-wave-container svg.small-downward-wave path{
    fill: #f7f7f7;
}

.integrations-small-wave-container svg.small-upward-wave path{
    fill: #f7f7f7;
}

.communication-channels-banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #c4e1ed;
    width: 100%;
}

.communication-channels-banner-title {
    font-size: 3rem;
    width: 40%;
    text-align: center;
}

.communication-channels-banner-subtitle {
    font-weight: 200;
    font-size: 1.5rem;
    width: 70%;
    margin-top: 0;
}


.communication-channels-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.communication-channel-box {
    display: flex;
    flex-direction: column;
    width: 20%;
    border: rgb(210, 230, 238) 1px solid;
    margin: 1vw;
    border-radius: 0.5rem;
    padding: 1%;
    align-items: start;
    background-color: #d2e6ee;
}

.communication-channel-box-image{
    width:30%
}

.communication-channel-box-description {
    text-align: left;
}

.software-integrations-banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(196, 225, 237);
    width: 100%;
}

.software-integrations-banner-title {
    font-size: 3rem;
    width: 40%;
    text-align: center;
    margin-top: 0;
}

.software-integrations-banner-subtitle {
    font-weight: 200;
    font-size: 1.5rem;
    width: 70%;
    margin-top: 0;
}

.software-integrations-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.software-integration-box {
    display: flex;
    flex-direction: column;
    width: 20%;
    border: rgb(210, 230, 238) 1px solid;
    margin: 1vw;
    border-radius: 0.5rem;
    padding: 1%;
    align-items: start;
    background-color: rgb(210, 230, 238);
}

.software-integration-box-image{
    width:50%
}

.software-integration-box-description {
    text-align: left;
}

@media (max-width: 768px) {
    .communication-channels-banner-title {
        font-size: 2rem;
        width: 90%;
        text-align: center;
    }

    .communication-channels-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .communication-channel-box {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .communication-channels-banner-subtitle {
        font-weight: 200;
        font-size: 1rem;
        width: 90%;
        margin-top: 0;
    }

    .communication-channel-box-title {
        margin: 0.5vh 0;
        font-size: 1rem;
        font-weight: 500;
        align-self: center;
    }

    .communication-channel-box-description {
        margin: 1.5vh 0;
        padding: 0 1.5vw;
        font-size: 0.8rem;
        font-weight: 300;
    }
    
    .communication-channel-box-image{
        width:20%;
        align-self: center;
    }

    .software-integrations-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .software-integrations-banner-title {
        font-size: 2rem;
        width: 90%;
        text-align: center;
    }

    .software-integrations-banner-subtitle {
        font-weight: 200;
        font-size: 1rem;
        width: 90%;
        margin-top: 0;
    }

    .software-integration-box {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .software-integration-box-image{
        width:30%;
        align-self: center;
    }

    .software-integration-box-title {
        margin: 0.5vh 0;
        font-size: 1rem;
        font-weight: 500;
        align-self: start;
    }

    .software-integration-box-description {
        margin: 1.5vh 0;
        padding: 0 1.5vw;
        font-size: 0.8rem;
        font-weight: 300;
    }

    .integrations-container .email-box-container {
        flex-direction: column;
        padding: 2.5vh 0;
      }
      .integrations-container .email-box-container p{
        font-size: 1.75rem;
      }
      .integrations-container .email-box-container .email-form-container {
        padding-top: 2.5vh;
      }
  
      .integrations-container .email-box-container .email-form-container a button{
        font-size: 1.5rem;
      }
    
}

