.benifits-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 50px;
    background-color: #c4e1ed;
    padding-top: 50px;
    contain: paint;
    align-items: center;
    /* background-image: linear-gradient(to bottom, #000002, #1d1d1f); */
    /* background-image: linear-gradient(to bottom, #fefefc, #f7f7f7); */
}

.benifits-container .robowar1 {
    position:absolute;
    top: 0;
    left: -2%;
    height: 45%;
    z-index: 1;
    /* transform: rotateY(180deg); */
}

.benifits-container .robowar2 {
    position:absolute;
    top:0;
    right: 0;
    height: 45%;
    z-index: 1;
}

.benifits-container h1 {
    font-size: 5rem;
    width: 75%;
    text-align: center;
    margin-bottom: 80px;
    font-weight: 600;
    color: #1d1d1f;
}

@media (max-width: 768px) {
    .benifits-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 50px;
        background-color: #c4e1ed;
        padding-top: 50px;
        contain: paint;
        align-items: center;
        /* background-image: linear-gradient(to bottom, #000002, #1d1d1f); */
        /* background-image: linear-gradient(to bottom, #fefefc, #f7f7f7); */
    }

    .benifits-container .robowar1 {
        display: none;
    }

    .benifits-container .robowar2 {
        display: none;
    }

    .benifits-container h1 {
        font-size: 3rem;
        width: 90%;
        margin-bottom: 50px;
    }
}