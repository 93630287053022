.home-container {
  background-color: #c4e1ed;
}

.home-email-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: #fefefc; */
    background-image: linear-gradient(to bottom, #fefefc, #c4e1ed);
    /* margin-bottom: 5%; */
}
.home-email-container .downward_wave {
    width: 100vw;
    object-fit:cover;
    z-index: 11;
    /* animation: glowing 2s infinite; */
}

.home-email-container svg.downward_wave path{
  fill: #1e63da86;
  /* animation: glowing 2s infinite; */
}

.home-email-container .upward_wave {
    width: 100vw;
    object-fit:cover;
    /* background-color: #1e62da; */
    /* background-color: #0000ff; Blue color (RGB: 0, 0, 255) */
    /* animation: glowing 2s infinite; */
}

.home-email-container svg.upward_wave path{
    fill: #1e63da86;
    /* color: #f7f7f7; */
}

.home-email-container .email-box-container p {
    color: #f7f7f7;
}

.home-email-container .email-box-container{
  background-color: #1e63da86;
  margin: 0;
  padding-top: 3%;
  /* animation: glowing 2s infinite; */
  z-index: 10;
}



@keyframes glowing {
    0% {
      box-shadow: 0 0 10px 2px rgba(0, 0, 255, 0.7);
    }
    50% {
      box-shadow: 0 0 20px 5px rgba(0, 0, 255, 0.5);
    }
    100% {
      box-shadow: 0 0 10px 2px rgba(0, 0, 255, 0.7);
    }
  }
  
  @media (max-width: 768px) {
    .home-email-container {
      /* display: none; */
      flex-direction: column;
    }
    .home-email-container .email-box-container {
      flex-direction: column;
      padding: 2.5vh 0;
    }
    .home-email-container .email-box-container p{
      font-size: 1.75rem;
    }
    .home-email-container .email-box-container .email-form-container {
      padding-top: 2.5vh;
    }

    .home-email-container .email-box-container .email-form-container a button{
      font-size: 1.5rem;
    }
  }
  
  
  