.products-container {
    /* height: 100%; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-container:nth-child(odd) .products-small-wave-container svg.small-upward-wave path{
    /* fill: #d2e3ee; */
    fill: #f7f7f7;
}

.product-container:nth-child(even) .products-small-wave-container svg.small-upward-wave path{
    fill: #d2e3ee;
}

.product-container:last-child .products-small-wave-container svg.small-upward-wave path{
    fill: #f7f7f7;
}

.product-container:nth-child(odd) {
    background-color: #d2e3ee;
}

.product-container .products-small-wave-container svg.small-upward-wave {
    height: 100%;
}

.product-container .products-small-wave-container {
    display: flex;
}

@media (max-width: 768px) {

      .product-container .email-box-container {
        flex-direction: column;
        padding: 2.5vh 0;
      }
      .product-container .email-box-container p{
        font-size: 1.75rem;
      }
      .product-container .email-box-container .email-form-container {
        padding-top: 2.5vh;
      }
  
      .product-container .email-box-container .email-form-container a button{
        font-size: 1.5rem;
      }
}