.email-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 3%;
}

.email-box-container-row {
    flex-direction: row;
    /* align-items: center; */
}

.email-box-container p {
    display: flex;
    font-size: 40px;
    margin: 0px 40px;
    font-weight: 700;
    /* text-align: center; */
    color: #007ca5;
}

.email-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 50%; */
    white-space: nowrap;
}

.email-form-container input {
    display: flex;
    height: 100%;
    /* flex: 2 2; */
    box-sizing: border-box;
    font-size: 30px;
    padding: 1% 5%;
    /* border: 1px solid #007ca5; */
    border: 1px solid #f37863;
    border-radius: 0.5rem;
    margin: 0px 5px;
}

.email-form-container button {
    display: flex;
    height: 100%;
    /* flex: 2 2; */
    align-items: center;
    font-size: 30px;
    padding: 10px 10px;
    border: 1px solid #f37863;
    border-radius: 0.5rem;
    margin: 0px 5px;
    background-color: #f37863;
    color: white;
}
