/* Header.css */
header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-left: 1vw;
    width: 99%;
    /* background-color: #f3eff0;
    border-bottom: 1px solid #d7dee6;
    box-shadow: 0 2.5px 8px rgba(0, 0, 0, 0.2); */

    border-bottom: ridge;
    border-bottom-style: hidden;
    box-shadow: 0 -3px 5px;

    transition: all 0.8s ease;
    z-index: 15;
    background: #f7f7f7;
    opacity: 85%;
    height: 7vh;
  }

  header.fixed-header-class
  {
    display: inline-flex;
    position: fixed;
  }

  header.scrolled {
    opacity: 97%;
  }

  a {
    text-decoration: none;
    color: #1d1d1f;
  }

  .logo {
      /* margin-right: 10px; */
      padding: 0 1vw;
      /* height: 45px; */
      /* width: 50px; */
      /* position: relative; */
      /* overflow: hidden; */
      
  }

  .logo img {
    width: 3vw;
    /* padding-bottom: 1vh; */
    /* height: 80%; */
    /* clip-path: polygon(0px 0px, 0px 100px, 100px 100px, 100px 0px); */
    /* clip-path: polygon(0px 0px, 0px 46px, 50px 46px, 50px 0px); */
  }

  .logo-text{
    font-size: 1.5rem;
    font-weight: 550;
    color: #1d1d1f;
    padding-right: 100px;
  }
  .logo-text p {
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  /* nav {
    position: relative;
  } */
  
  .hamburger {
    display: none;
    cursor: pointer;
    padding: 5px;
  }
  
  nav ul {
    font-weight: 530;
    font-size: 20px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    padding: 10px 40px;
  }

  nav ul li:hover{
    text-decoration: underline;
    text-decoration-color: #1d1d1f;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }

  nav ul li.dropdown{
    padding-right: 30px;
  }

  .dropdown {
    position: relative;
  }

  nav ul li.dropdown:hover .dropdown-container {
    display: block;
  }

  .dropdown-container {
    display: none;
    position: absolute;
    top: 30px;
  }

  
  .dropdown-content {
    /* display: none; */
    position: relative;
    top: 23px;
    background-color: #f3eff0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
    /* left: 40px; */
  }
  
  .dropdown-content a {
    display: block;
    margin-bottom: 5px;
    color: #1d1d1f;
    text-decoration: none;
    white-space: nowrap;
    padding: 15px;
    padding-right: 30px;
    text-align: left;
  }

  .dropdown-logo {
    vertical-align: middle;
    height: 30px;
    background-color: transparent;
  }
  
  .dropdown-content a:hover {
    color: #666;
  }

  .menu-arrow {
    margin-top: -2px;
    margin-left: 2px;
    vertical-align: middle;
  }

.header-class .asttecs-container{
    display: flex;
    /* max-width: 50vw; */
    /* width: 100%; */
    /* align-self: center; */
    /* justify-self: end; */
    /* padding-bottom: 20px; */
    padding-right: 1vw;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
    /* justify-self: flex-end; */
}

.header-class .asttecs-container p{
    margin: 0 20px;
    font-size: 0.75rem;
}

.header-class .asttecs-logo {
    width: 10vw;
    /* align-self: end; */;
    /* width: 192px; */
    /* height: 75px; */
    /* clip-path: inset(0px, 25px, 0px 25px); */
    /* overflow: hidden; */
}

.header-class img.asttecs-logo{
    /* clip-path: polygon(0px 25px, 192px 25px, 192px 75px, 0px 75px); */
    /* clip-path: inset(0px, 600px, 0px 600px); */
}
  
  /* Responsive Styling */
  @media (max-width: 768px) {

    a {
        color: #2e5e90;
    }

    header {
      justify-content: space-between;
      opacity: 98%;
      /* padding: 5px 10px; */
      /* width:fit-content */
    }

    header.scrolled {
      opacity: 98%;
    }

    .logo img {
      width: 7vw;
    }

    .logo-text{
      font-size: 1.4rem;
      padding-right: 0px;
      justify-self: start;
      align-self: center;
    }

    nav {
        position: absolute;
        top: 7vh;
        width: 100vw;
        display: flex;
        left: 0;
    }

  .header-class .asttecs-container{
      display: flex;
      /* max-width: 50vw; */
      /* width: 100%; */
      /* align-self: center; */
      /* justify-self: end; */
      /* padding-bottom: 20px; */
      padding-right: 1vw;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      width: 90%;
      /* justify-self: flex-end; */
  }
  .header-class .asttecs-container p {
    display: none;
  }
  .header-class .asttecs-logo {
    width: 25vw;
    /* align-self: end; */;
    /* width: 192px; */
    /* height: 75px; */
    /* clip-path: inset(0px, 25px, 0px 25px); */
    /* overflow: hidden; */
  }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .line {
      width: 25px;
      height: 3px;
      background-color: #1d1d1f;
      margin: 2px 0;
    }
  
    nav ul {
      flex-direction: column;
      display: none;
      /* position: fixed; */
      /* top: 49px;
      left: 0;
      width: 100%; */
      background-color: #f2f2f2;
      padding: 10px;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.1);
    }
  
    nav.open ul{
      display: flex;
      text-align: center;
      width: 100vw;
    }
  
    nav ul li {
        margin: 0.5rem 0;
        border-radius: 2px;
        border-color: #e51dc7;
      /* margin-right: 0;
      margin-bottom: 10px; */
    }
  
    .dropdown {
      position: static;
    }
  
    .dropdown-content {
      position: static;
      box-shadow: none;
      display: none;
      margin-top: 0;
    }
  }
  