.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  /* background-color: #1d1d1f; */
  background-color: #f7f7f7;
  /* background-color: #E6DEDF; */
  /* background-image: url('./images/Designer.png');
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  /* font-family: 'Roboto', sans-serif; */
  /* color: #dfdfea; */
  margin: 0;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .test {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.chat {
  /* display: none; */
  position: fixed;
  bottom: 0;
  overflow: hidden;
  opacity: 1;
  right: 0;
  height: 60%;
  width: 25%;
  border: 0px;
  z-index: 2147483639;
}