.product-box-container{
    display: flex;
    padding: 3% 0;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20vh;
    /* height: 70vh; */
}

.product-header h1{
    display: flex;
    font-size: 3rem;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0px;
}

.product-header h3{
    font-weight: 200;
    font-size: 1.25rem;
    /* margin-top: 0px; */
    text-align: left;
}

.product-text-pane {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.product-image-pane {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* height: 50vh; */
    width: 45%;
}

.product-image {
    padding: 0px 20px;
    display: flex;
    width: 100%;
    height: 100%;
    /* max-height: 720px; */
    aspect-ratio: calc(720/608);
    /* min-height: 720px; */
    /* min-width: 608px;
    max-width: 608px; */
    object-fit: cover;
}

@media (max-width: 768px) {
    .product-box-container{
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20vh;
        /* height: 70vh; */
    }

    .product-text-pane {
        display: flex;
        flex-direction: column;
        width: 90vw;
        padding: 0px 5vw;
        padding-bottom: 5vw;
    }

    .product-header h1 {
        font-size: 2rem;
    }

    .product-header h3 {
        font-size: 0.75rem;
    }

    .product-image-pane {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /* height: 50vh; */
        width: 100vw;
        padding: 0px;
    }

    .product-image {
        padding: 0 5vw;
        display: flex;
        width: 90vw;
        aspect-ratio: unset;
        /* height: 100%; */
    }
    .product-image img {
        display: flex;
        width: 100%;
        aspect-ratio: unset;
        /* height: 100%; */
    }
}
