.banner-container {
    /* width: 100vw; */
    display: flex;
    background-color: #fefefc;
    /* background-color: #000002; */
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}
.banner-text {
    display: flex;
    top: 20%;
    left: 5%;
    /* transform: translate(-20vh, -30vh); */
    z-index: 2;
    position:absolute;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    /* color: #f7f7f7; */
    /* color: #20705c; */
    /* color: #007ca5; */
    /* text-shadow: #f7f7f7; */
    align-items: center;
    font-family: 'Outfit', sans-serif;
    overflow: hidden;
}
.banner-text h1 {
    /* font-size: 6rem; */
    font-size: 4.5vw;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-block-start: 1.5rem;
    color: #f37863;
    /* -webkit-text-stroke: 2px #007ca5; */
    /* -webkit-text-stroke: 1px #f7f7f7; */
    /* text-shadow: 0px 5px 15px #c9ad8a; */
    /* text-shadow: 0px 5px 65px #99856d; */
    /* text-shadow: -2px -2px 0 #007ca5, 1px -1px 0 #007ca5, -1px 1px 0 #007ca5, 1px 1px 0 #007ca5; */
}
.banner-text h2 {
    /* font-size: 3rem; */
    font-size: 2.25vw;
    font-weight: 400;
    margin-bottom: 1rem;
    color:#007ca5;
    text-shadow: 0px 5px 13px #98a6ab;
    /* -webkit-text-stroke: 1px #007ca5; */
}
.banner-video {
    position:relative;
    width: 100vw;
    height: 100vh;
}

.banner-video video{
    /* width: 300px;
    height: 550px; */
    background: none;
    width: 100%;
    /* aspect-ratio: calc(5/3); */
    object-fit:cover;
    height: 100%;
}

.banner-logo-container{
    height: 10%;
    width: 15%;
}

img.banner-logo{
    display: flex;
    width: 100%;
    /* clip-path: polygon(0px 0px, 0px 135px, 150px 135px, 150px 0px); */
}

/* Responsive Styling */
@media (max-width: 1440px) {
    .banner-container {
        justify-content: center;
        height: 100vh;
        flex-direction: column;
        overflow: hidden;
        /* width: 100vw; */
    }
    .banner-video video{
        /* width: 300px;
        height: 550px; */
        /* background: none; */
        width: 170%;
        /* aspect-ratio: calc(5/3); */
        object-fit:cover;
        height: 100vh;
    }

    .banner-video video::-webkit-media-controls-start-playback-button {
        display: none;
    }

    .banner-video video::-webkit-media-controls-panel {
        display: none !important;
        opacity: 1 !important;
    }

    .banner-text {
        display: flex;
        top: unset;
        left: unset;
        /* transform: translate(-20vh, -30vh); */
        z-index: 2;
        position: flex;
        width: 90vw;
        flex-direction: column;
        justify-content: center;
        padding: 0px 10px;
        /* color: #f7f7f7; */
        /* color: #20705c; */
        /* color: #007ca5; */
        /* text-shadow: #f7f7f7; */
        align-items: center;
        font-family: 'Outfit', sans-serif;
        overflow: hidden;
    }

    .banner-text h1 {
        font-size: 3rem;
    };
}

/* .full-banner-video {
    width: 100%;
    display: flex;
}
.full-banner-video video {
    width: 100%;
    background: none;
}
.banner-video-overlay {
    position: absolute;
    overflow: hidden;
    opacity: 1;
    right: 0;
    height: 80%;
    width: 20%;
    border: 0px;
    z-index: 2147483639;
} */