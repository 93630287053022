footer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 300;
    padding: 1vh 1vw;
    padding-top: 2vh;
    border-top: ridge;
    border-top-style: hidden;
    box-shadow: 0 2.5px 5px;
    /* width: 100vw; */
    color: #1d1d1f;
}

footer p {
    margin: 0;
}

footer .asttecs-container{
    display: flex;
    padding-bottom: 1vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

footer .asttecs-container a{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

footer .asttecs-container p{
    margin: 0 20px;
    font-size: 0.75rem;
}

footer .asttecs-logo {
    width: 10%;
    align-self: center;
    /* width: 192px; */
    /* height: 75px; */
    /* clip-path: inset(0px, 25px, 0px 25px); */
    /* overflow: hidden; */
}

footer img.asttecs-logo{
    /* clip-path: polygon(0px 25px, 192px 25px, 192px 75px, 0px 75px); */
    /* clip-path: inset(0px, 600px, 0px 600px); */
}

@media (max-width: 768px) {
    footer {
        padding: 1vh 0px;
        margin: 0px;
    }
    footer p {
        margin: 0;
        font-size: 0.75rem;
        padding-bottom: 1vh;
    }
    footer .asttecs-container {
        flex-direction: column;
        padding-bottom: 1vh;
    }
    footer .asttecs-logo {
        width: 20%;
        align-self: center;
    }
}