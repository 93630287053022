.pricing-box-container {
    display: flex;
    width: 20vw;
    flex-direction: column;
    /* border: 1px solid black; */
    /* padding: 10px; */
    border-radius: 0.5rem;
    border-width: 1px;
    /* border-color: #ebd2d4; */
    background-color: white;
    box-shadow: 0 0px 5px rgb(0, 0, 0, 0.082);
    /* border-style: ridge; */
    margin: 15px;
    z-index: 3;
    /* background-image: linear-gradient(to right, #c4e1ed, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7,  #c4e1ed); */
    /* background-image: linear-gradient(to right, #c4e1ed, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7, #f7f7f7,  #c4e1ed); */
}




.pricing-box-container:hover {
    box-shadow:  0 0px 10px 5px rgba(0, 0,0, 0.12);
    z-index: 10;
    /* background-color: #E6DEDF; */
}

.pricing-box-container img {
    height: 120px;
    margin: 0;
}

.pricing-box-container h3 {
    /* font-size: 1.5rem; */
    font-weight: 550;
}

.pricing-box-container p {
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 1.7rem;
    text-align: left;
}

.plan-pricing-period{
    font-weight: 200;
    font-size: 1.25rem;
}

.plan-pricing-pretext {
    font-weight: 200;
    font-size: 1rem;
}


@media (max-width: 768px) {
    .pricing-box-container {
        /* width: 90vw; */
        margin: 0 0.5vw;
        width: 22vw;
    }

    .pricing-box-container img {
        height: 70px;
        margin: 0;
    }

    .plan-pricing-pretext {
        font-size: 0.65rem;
    }

    .plan-pricing-period{
        font-weight: 200;
        font-size: 0.65rem;
    }
}