.benifit-box-container {
    display: flex;
    /* width: 70%; */
    flex-direction: row;
    justify-content: space-around;
    /* height: 20%; */
    margin: 0px auto;
    /* border: 1px solid black; */
    padding: 0 10%;
    background-image: linear-gradient(to top, #dee7ec, #c4e1ed0d);
    z-index: 2;
    }

.benifit-box-container:nth-child(odd) {
    flex-direction: row-reverse;
    background-image: linear-gradient(to bottom, #dee7ec, #c4e1ed0d);
    /* box-shadow: rgb(220, 204, 205) 7px 1px 8px -5px; */
}

.benifit-box-container {
    /* box-shadow: 0 0px 10px 5px rgb(220, 204, 205); */
    /* box-shadow: rgb(220, 204, 205) -7px 1px 8px -5px; */
    /* z-index: 100; */
    /* background-color: #E6DEDF; */
}

.benifit-box-container img {
    /* height: 40px; */
    width: 25%;
    margin: 20px 10px;
}



.benifit-box-container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    /* color: #f7f7f7; */
    color: #1d1d1f;
}

.benifit-box-container-text h3 {
    font-size: 3rem;
    /* color: #1d1d1f; */
    font-weight: 600;
    margin: 20px 10px;
    opacity: 1;
}

.benifit-box-container-text p {
    font-size: 1.5rem;
    font-weight: 350;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* color: #1d1d1f; */
    margin: 20px 10px;
    letter-spacing: 0.5px;
    /* line-height: 1.7rem; */
    text-align: left;
}

.benifit-box-link {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin: 0px 10px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #1e62da;
}

.benifit-box-link a {
    color: #1e62da;
}

.benifit-box-link a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .benifit-box-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 0;
    }
    .benifit-box-container:nth-child(odd) {
        flex-direction: column;
    }

    .benifit-box-container img {
        /* height: 40px; */
        width: 70%;
        margin: 20px 10px;
    }
    .benifit-box-container-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        /* color: #f7f7f7; */
        color: #1d1d1f;
    }
    .benifit-box-container-text h3 {
        font-size: 2rem;
        /* color: #1d1d1f; */
        font-weight: 600;
        /* margin: 20px 10px; */
        opacity: 1;
    }

    .benifit-box-container-text p {
        display: none;
    }
    .benifit-box-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: start;
        margin: 0px 10px;
        font-size: 1rem;
        font-weight: 500;
        color: #1e62da;
    }
}