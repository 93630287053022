.contact-page-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    background-color: #d2e6ee;
    padding-bottom: 3vh;
}

.contact-form-pane-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2vh 4vw;
    /* height: 15%; */
    /* overflow: hidden; */
}

.contact-form-pane-header h1 {
    font-size: 3em;
    font-weight: 550;
    margin: 0;
}

.contact-form-pane-header h2 {
    font-size: 1.5em;
    font-weight: 200;
    margin: 0;
}

.contact-form-pane {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 70%;
    height: 100%;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: 80%;
    background-color: #f7f7f7;;
    margin-left: 4vw;
    margin-top: 1vh;
    margin-bottom: 5vh;
    padding: 3vh 3vw;
    padding-top: 3.5vh;
    padding-bottom: 1.5vh;
    /* min-height: 70vh; */
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    height: 100%;
    /* height: 70vh; */
    /* max-height: 100%; */
}

.contact-form-field-label {
    display: flex;
    width: 90%;
    font-size: 1.10rem;
    border-radius: 0.5rem;
    margin: 0 0;
    margin-top: 0%;
    padding-left: 2%;
}

.contact-form input {
    display: flex;
    width: 90%;
    font-size: 1.10rem;
    border-radius: 0.5rem;
    padding: 1.1% 1%;
    margin: 1% 1%;
    border-width: 1px;
    border-color: #bab6b661;
}

.contact-form textarea {
    display: flex;
    width: 90%;
    font-size: 1.10rem;
    border-radius: 0.5rem;
    padding: 1.1% 1%;
    margin: 1% 1%;
    border-width: 1px;
    border-color: #bab6b661;
    height: 100%;
    resize: none;
    border-bottom-style: inset;
}

.contact-form input:placeholder-shown {
    font-size: 1.10rem;
    font-style: italic;
    opacity: 0.5;
} 

.contact-form textarea:placeholder-shown {
    font-size: 1.10rem;
    font-style: italic;
    opacity: 0.5;
} 

.contact-form-message-input {
    min-height: 100px;
    /* height: fit-content; */
}

.contact-form button {
    display: flex;
    font-size: 150%;
    border-radius: 0.25rem;
    padding: 1% 1%;
    margin: 1%;
    background-color: #f37863;
    color: white;
    border: none;
    width: 20%;
    text-align: center;
    justify-content: center;
}

.contact-form button:hover {
    background-color: #d53b20;
    color: white;
    cursor: pointer;
}

.contact-form button:visited {
    background-color: #20d53b;
    color: white;
}

.contact-form-error {
    color: red;
    font-size: 1.1rem;
    font-weight: 500;
}

.contact-info-pane {
    display: flex;
    flex-direction: column;
    padding: 3vh 3vw;
    padding-top: 15vh;
}

.contact-info-item {
    display: flex;
    padding: 0 0;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin: 3vh 0;
    margin-right: 5vw;
    color: #f37863;
}

.contact-info-item h3 {
    font-size: 2rem;
    font-weight: 550;
    margin: 0;
}

.contact-info-item p {
    font-size: 1.5rem;
    font-weight: 250;
    margin: 0;
}


@media (max-width: 768px) {
    .contact-page-container {
        flex-direction: column;
    }

    .contact-form-pane {
        width: 100%;
        height: 100%;
    }

    .contact-form-pane-header {
        width: 90%;    
        /* padding: 1vh 2vw; */
    }

    .contact-form-pane-header h1 {
        font-size: 1.8em;
        font-weight: 550;
        margin: 0;
    }
    
    .contact-form-pane-header h2 {
        font-size: 1em;
        font-weight: 200;
        margin: 0;
    }

    .contact-form {
        width: 85%;
        margin-left: 4vw;
        margin-right: 4vw;
        margin-top: 1vh;
        margin-bottom: 5vh;
        padding: 3vh 3vw;
        padding-top: 3.5vh;
        padding-bottom: 1.5vh;
        /* padding: 0; */
        /* margin: 0; */
    }

    .contact-form button {
        width: 30%;
    }

    .contact-info-pane {
        width: 100%;
        height: 90%;
        padding: 0;
        margin: 0;
    }

    .contact-info-item {
        margin: 0;
        margin-bottom: 5vh;
    }
}