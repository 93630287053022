.pricing-page-container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-image: linear-gradient(to top, #f7f7f7, #c4e1ed); */
    background-color: #f7f7f7;
}

.pricing-page-container .robowar1 {
    position:absolute;
    top: 0;
    left: -2%;
    height: 75%;
    z-index: 1;
    /* transform: rotateY(180deg); */
}

.pricing-page-container .robowar2 {
    position:absolute;
    top:0;
    right: 0;
    height: 75%;
    z-index: 1;
}

.pricing-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
    color: #1d1d1f;
}

.pricing-page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
    z-index: 2;
}

.pricing-page-header h1{
    font-size: 3rem;
    text-align: center;
}

.pricing-page-header p {
    font-weight: 200;
    font-size: 1.5rem;
    margin-top: 0;
}

.plan-header{
    font-size: 2.55rem;
    font-weight: 550;
    color: white;
    margin: 20px 10px;
    height: 75px;
    background-image: linear-gradient(to top, #bbdeec, #93c6db, #bbdeec);
    border-radius: 0.5rem;
}

table {
    border-spacing: 0;
}

.titles-table{
    /* padding: 10px; */
    width: 15vw;
    margin: 15px;
    /* background-image: linear-gradient(to right, #c4e1ed, #f7f7f7, #c4e1ed); */
    /* background-image: linear-gradient(to left, #f7f7f7, #c4e1ed); */
}

.titles-table .plan-header{
    background-image: none;
}

.pricing-container td {
    text-align: center;
    /* box-shadow: inset 15px 0 15px -25px black; */
    border-top: 0px solid #ddd;
    border-bottom: 0px solid #ddd;
    /* border: 2px solid forestgreen; */
    /* border-radius: 30%; */
    /* width: 10vh;
    height: 30px; */
    padding: 10px;
    box-shadow: inset 0 -15px 15px -28px black;
    /* box-shadow: inset 15px 0 15px -15px black;//left   box-shadow: inset -15px 0 15px -15px black;//right   box-shadow: inset 0 15px 15px -15px black;//top   box-shadow: inset 0 -15px 15px -15px black;//bottom */
}

.pricing-container tr:last-child td{
    box-shadow: none;
}

.pricing-container th{
    text-align: center;
    /* height: max-content; */
    padding: 0;
    /* border-spacing: 0; */
    /* font-size: 2rem; */
    /* width: 10vh; */
    /* box-shadow: inset 0px -6px 20px -20px #000000; */
}

.plan-image{
    height: 145px;
}

.row-header{
    text-align: left;
    font-size: 1rem;
}

.plan-pricing {
    height: 120px;
    font-size: 2rem;
    width: fit-content;
    white-space: pre-wrap;
}

.plan-installation-pricing {
    height: 40px;
}

.plan-pricing-currency {
    font-size: 1.25rem;
    vertical-align: super;
    white-space: pre-wrap;
}

.plan-feature {
    height: 40px;
}


.pricing-contact-container .email-box-container {
    margin: 0%;
    padding-bottom: 3%;
    background-color: #c4e1ed;
}

.pricing-small-wave-container {
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.pricing-small-wave-container svg.small-downward-wave path{
    fill: #c4e1ed;
}

.pricing-small-wave-container svg.small-upward-wave path{
    fill: #c4e1ed;
}

@media (max-width: 768px) {

    .pricing-page-container {
        /* margin: 0 1vw; */
    }

    .pricing-page-container .robowar1 {
        display: none;
        /* transform: rotateY(180deg); */
    }
    
    .pricing-page-container .robowar2 {
        display: none;
    }

    .pricing-page-header h1{
        font-size: 2rem;
        padding: 0 2vw;
    }

    .pricing-page-header p {
        font-size: 1rem;
    }

    .titles-table {
        margin: 0 0.5vw;
        width: 27vw;
    }

    .pricing-container{
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .pricing-container td {
        text-align: center;
        border-top: 0px solid #ddd;
        border-bottom: 0px solid #ddd;
        padding: 0.25vh 1vw;
        box-shadow: inset 0 -15px 15px -28px black;
    }

    .plan-header{
        font-size: 1.25rem;
        font-weight: 550;
        color: white;
        margin: 0px 0px;
        height: 15vh;
        writing-mode: vertical-lr;
        /* -moz-transform: scale(-1, -1); */
        /* -webkit-transform: scale(-1, -1); */
        /* -o-transform: scale(-1, -1); */
        /* -ms-transform: scale(-1, -1); */
        /* transform: scale(-1, -1); */
        text-orientation: mixed;
    }

    .row-header{
        font-size: 0.75rem;
    }

    .plan-image {
        height: 80px;
    }

    .plan-pricing {
        height: 80px;
        font-size: 1rem;
    }

    .plan-pricing-currency {
        font-size: 0.5rem;
        vertical-align: super;
        white-space: pre-wrap;
    }
    
    .plan-feature {
        font-size: 0.65rem;
        /* height: 75px; */
    }

    .plan-pricing .email-box-container {
        margin: 0%;
    }

    .plan-pricing .email-box-container .email-form-container a button{
        font-size: 0.65rem;
      }

    .pricing-contact-container .email-box-container {
      flex-direction: column;
      padding: 2.5vh 0;
    }
    .pricing-contact-container .email-box-container p{
      font-size: 1.75rem;
    }
    .pricing-contact-container .email-box-container .email-form-container {
      padding-top: 2.5vh;
    }

    .pricing-contact-container .email-box-container .email-form-container a button{
      font-size: 1.5rem;
    }
}