html{
  /* background-color: #E6DEDF; */
  margin: 0;
  min-height: 100%;
  max-width: 100%;
  display: flex;
  overflow-x: hidden;
  font-family: 'Outfit', sans-serif;
}
#root {
  margin: 0;
  min-height: 100%;
  width: 100%;
  display: flex;
}
body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

@font-face {
  font-family: 'Outfit';
  src: url('../public/fonts/Outfit/Outfit-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* this is the range of your variable font */
  font-style: normal;
}
